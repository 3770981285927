import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./App.css";

function PGPModal({ isOpen, onClose, publicKey, fingerprint }) {
  const [language, setLanguage] = useState("en");
  const textAreaRef = useRef(null);

  if (!isOpen) return null;

  const copyToClipboard = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      textAreaRef.current.setSelectionRange(0, 99999); // 对于移动设备
      try {
        document.execCommand("copy");
        alert(
          language === "en"
            ? "Public key copied to clipboard"
            : language === "zh"
            ? "公钥已复制到剪贴板"
            : "公開鍵がクリップボードにコピーされました"
        );
      } catch (err) {
        alert(
          language === "en"
            ? "Failed to copy. Please copy manually."
            : language === "zh"
            ? "复制失败，请手动复制。"
            : "コピーに失敗しました。手動でコピーしてください。"
        );
      }
    }
  };

  const downloadPublicKey = () => {
    const element = document.createElement("a");
    const file = new Blob([publicKey], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "RYO SHIN (696403E7) – Public.asc";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    // 对于iOS设备，提供额外的指导
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      alert(
        language === "en"
          ? "For iOS devices: Please use the 'Share' button in your browser to save or share the public key file."
          : language === "zh"
          ? "对于iOS设备：请使用浏览器中的共��」按钮来保存或分享公钥文件。"
          : "iOSデバイスの場合：ブラウザの「共有」ボタンを使用して、公開鍵ファイルを保存または共有してください。"
      );
    }
  };

  const descriptions = {
    en: "PGP (Pretty Good Privacy) is an encryption technology used to encrypt emails and other sensitive data. Using my public key, you can send me encrypted messages, ensuring the security and privacy of our communication.",
    zh: "PGP（Pretty Good Privacy）是一种加密技术，可用于加密电子邮件和其他敏感数据。使用我的公钥，您可以向我发送加密消息，确保我们通信的安全性和私密性。",
    ja: "PGP（Pretty Good Privacy）は、電子メールやその他の機密データを暗号化するための技術です。私の公開鍵を使用することで、暗号化されたメッセージを送信でき、通信のセキュリティとプライバシーを確保できます。",
  };

  const buttonTexts = {
    en: {
      copy: "Copy Public Key",
      download: "Download Public Key",
      close: "Close",
    },
    zh: { copy: "复制公钥", download: "下载公钥", close: "关闭" },
    ja: {
      copy: "公開鍵をコピー",
      download: "公開鍵をダウンロード",
      close: "閉じる",
    },
  };

  return (
    <div className="pgp-modal-overlay" onClick={onClose}>
      <div className="pgp-modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>PGP Public Key</h2>
        <div className="language-buttons">
          <button onClick={() => setLanguage("en")}>English</button>
          <button onClick={() => setLanguage("zh")}>中文</button>
          <button onClick={() => setLanguage("ja")}>日本語</button>
        </div>
        <p>{descriptions[language]}</p>
        <h3>Fingerprint:</h3>
        <pre>{fingerprint}</pre>
        <h3>Public Key:</h3>
        <textarea
          ref={textAreaRef}
          value={publicKey}
          readOnly
          style={{ position: "absolute", left: "-9999px" }}
        />
        <pre>{publicKey}</pre>
        <div className="pgp-modal-buttons">
          <button onClick={copyToClipboard}>
            {buttonTexts[language].copy}
          </button>
          <button onClick={downloadPublicKey}>
            {buttonTexts[language].download}
          </button>
          <button onClick={onClose}>{buttonTexts[language].close}</button>
        </div>
      </div>
    </div>
  );
}

function IMessageModal({ isOpen, onClose, iMessageCode }) {
  const [language, setLanguage] = useState("en");
  const textAreaRef = useRef(null);

  if (!isOpen) return null;

  const copyToClipboard = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      textAreaRef.current.setSelectionRange(0, 99999); // 对于移动设备
      try {
        document.execCommand("copy");
        alert(
          language === "en"
            ? "iMessage code copied to clipboard"
            : language === "zh"
            ? "iMessage代码已复制到剪贴板"
            : "iMessageコードがクリップボードにコピーされました"
        );
      } catch (err) {
        alert(
          language === "en"
            ? "Failed to copy. Please copy manually."
            : language === "zh"
            ? "复制失败，请手动复制。"
            : "コピーに失敗しました。手動でコピーしてください。"
        );
      }
    }
  };

  const descriptions = {
    en: "iMessage Contact Key Verification provides additional security by helping to detect sophisticated threats against iMessage servers and allowing you to verify that you're messaging only with the people that you intend.",
    zh: "iMessage联系人密钥验证通过帮助检测针对iMessage服务器的复杂威胁，并允许您验证您只与您想要联系的人进行消息交流，从而提��额外的安全性。",
    ja: "iMessageコンタクトキー検証は、iMessageサーバーに対する高度な脅威を検出し、意図した相手とのみメッセージをやり取りしていることを確認できるようにすることで、追加のセキュリティを提供します。",
  };

  const buttonTexts = {
    en: { copy: "Copy Code", close: "Close" },
    zh: { copy: "复制代码", close: "关闭" },
    ja: { copy: "コードをコピー", close: "閉じる" },
  };

  const appleLinks = {
    en: "https://support.apple.com/en-us/118246",
    zh: "https://support.apple.com/zh-cn/118246",
    ja: "https://support.apple.com/ja-jp/118246",
  };

  return (
    <div className="pgp-modal-overlay" onClick={onClose}>
      <div className="pgp-modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>iMessage Contact Key Verification</h2>
        <div className="language-buttons">
          <button onClick={() => setLanguage("en")}>English</button>
          <button onClick={() => setLanguage("zh")}>中文</button>
          <button onClick={() => setLanguage("ja")}>日本語</button>
        </div>
        <p>{descriptions[language]}</p>
        <textarea
          ref={textAreaRef}
          value={iMessageCode}
          readOnly
          style={{ position: "absolute", left: "-9999px" }}
        />
        <pre>{iMessageCode}</pre>
        <p>
          <a
            href={appleLinks[language]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {language === "en"
              ? "Learn more about iMessage Contact Key Verification"
              : language === "zh"
              ? "了解更多关于iMessage联系人密钥验证的信息"
              : "iMessageコンタクトキー検証についてもっと学ぶ"}
          </a>
        </p>
        <div className="pgp-modal-buttons">
          <button onClick={copyToClipboard}>
            {buttonTexts[language].copy}
          </button>
          <button onClick={onClose}>{buttonTexts[language].close}</button>
        </div>
      </div>
    </div>
  );
}

function ContactModal({ isOpen, onClose, onSelectEmail, onSelectIMessage }) {
  if (!isOpen) return null;

  return (
    <div className="pgp-modal-overlay" onClick={onClose}>
      <div className="pgp-modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Method</h2>
        <div className="contact-buttons" style={{ gap: "10px" }}>
          <button onClick={onSelectEmail}>PGP</button>
          <button onClick={onSelectIMessage}>
            iMessage Public Verification Code
          </button>
        </div>
        <button onClick={onClose} style={{ marginTop: "10px" }}>
          Close
        </button>
      </div>
    </div>
  );
}

function App() {
  const [nameIndex, setNameIndex] = useState(0);
  const names = [
    { text: "梁 震", subtext: "LIANG ZHEN" },
    { text: "梁 震", subtext: "リョウ シン" },
    { text: "SHIN RYO", subtext: "ʃɪn rjoʊ" },
  ];
  const nameRef = useRef(null);
  const subtextRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      gsap.to(nameRef.current, {
        duration: 0.5,
        opacity: 0,
        y: -20,
        onComplete: () => {
          setNameIndex((prevIndex) => (prevIndex + 1) % names.length);
          gsap.fromTo(
            nameRef.current,
            { y: 20 },
            { duration: 0.5, opacity: 1, y: 0 }
          );
        },
      });
      gsap.to(subtextRef.current, {
        duration: 0.5,
        opacity: 0,
        y: -20,
        onComplete: () => {
          gsap.fromTo(
            subtextRef.current,
            { y: 20 },
            { duration: 0.5, opacity: 1, y: 0 }
          );
        },
      });
    }, 3000);
    return () => clearInterval(interval);
  }, [names.length]);

  const [isPGPModalOpen, setIsPGPModalOpen] = useState(false);
  const [isIMessageModalOpen, setIsIMessageModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const pgpFingerprint = "10D7 0049 8FEA DA4A D656  88A5 F1FF B06F 6964 03E7";
  const pgpPublicKey = `-----BEGIN PGP PUBLIC KEY BLOCK-----

mQINBGbhNl8BEADKJ1UmwEhyk3JXNAubue38mCbHDzCVT0FpuoPWKe/thM3lFbzp
edfsg8LcKLLa2Q1WcJnL9vjw2izZDcqSnTj7qZYiDfySxPYwbgm6lA3FykvFKeVq
d8+iwBNNi+VTYSxhdKVYAKPm6U4FIc1wtPyeF83Byak/XDzbF6Vq0mvBcwKBiBZf
JRGQpkswIobyhmkUAI34Gr7281Wryz4NkX9yLEJsnqwIdE7hVkkGnlZ24CPfmHpr
d7F2fjwPCL5gMC9mdRLBm1YpA3xiWXZzGJGZklxeLLzyIA/wU+x0XA6q8t7P6qET
Qa2+OtZEy7AIhP0C+BUbp1GjzWemCgejY1IAwE+SZzXbyJ9OypmAhfIDENtOl+We
af1rBsMKB8vnXNrqKHXzx1UyXDZjGm0kZ8/loq7EnYn8xNswRkVZCnJvbM4a5aLy
zottRy1eVMs4S+VXeZIc0N4zktKMsKPp18yHfkGJbKjUmseqqoPIkDfZD23FxnB4
9bt5AiBmlCK9wK9EZJvzcsWKzezhqAF07mFcssHPrR5S7JEJYGvJCNsq2153QZW7
lzg7vAN3l5R/cH/U/WNyXYwdZ+QCF3F0vxmU1ilIGSxzD3tpIafi/BCIshQGd42I
IOhlguMCEwacpdNntLpsu9YfjlQ+89Q0itNSTvLfC3fr8mQFayZBhTPvnQARAQAB
tCFSWU8gU0hJTiA8cnlvLnNoaW4uODNAaWNsb3VkLmNvbT6JAk4EEwEIADgWIQQQ
1wBJj+raStZWiKXx/7BvaWQD5wUCZuE2XwIbAwULCQgHAgYVCgkICwIEFgIDAQIe
AQIXgAAKCRDx/7BvaWQD59VFEACtrGn6R93BIeQYvX7x0QfAf2eXXzZyDdC4WpQv
KZonkJTxNLJvkP+KsLHow8kC5xae0fqFEoKTivO8rrUBLSZJbJ4Xc0oGhBh/J46o
lBeA/DoVAorHt5wlA/QZ9KX01FOkKJD//7ppDUU+0MYwEAlg3JNBSdpMyAynEt1w
kOin+1XjKrZ6eGn0uzfMRyB5UEqiuhbWxLp7dg7SIEVj+T0sccbxZbauQU4yL5x0
/rhjapDEtsstbq5o1RShP3j4T7FwhXFKQudhalDPwG4DT7s8z8n/QSq1WZYWTVSp
l7aMRUaxsDVMeBhOcNOERsPCqbgU+t4WjQOoXVT82z91Sz74H1w7z2bvHdmEuHPx
AeN/6Ncu9DFsWqDe6Ogv1cEX3tQKcsBI1HvBqywMEK+GeEdz4xfTZPr/8+eZH2qE
uAttIs2RUeLr9EgAMJ2QgYzErdQ9ju3rH6/I4tjb+xYp3f226cAxtmEdcHnBeTMG
0Juh1CTTI46kN8PydkOpsT4PyrtWaSmMfW/H4YmOg+a5u+E90LLalVu2dpzsYh58
8n7dcqqph6CnK0CC1Mnfb2y/a7KK2mfPIXiJqdladLQ1OqsPU9G6NeHf/QNuSdET
x4bSmW+W+nIxbwWoBHUGT9E3Jk3TLdPJdI7dpLYqMbDYjh8dIeJMB7y9vPYuXbZj
TiLWyLkCDQRm4TZfARAAxS2R3vNgj+HLSHxgdjqU0yJ44212PsGU70WUYAPq81LF
z/ejFADXeV4y1FiqjiB7h0DB/ylff00av0t2cP43L5t0cMN1I9C1FBjIgASqNgUB
sRYxUwv/SwiAIfPP37m7Ao37kMHfFWQTdvZlpVFOeANsciJzdBYKE/QXmU+E6g6v
wPOZ6ZwW9fjzY5GrijmQJbWgKXxhP2FPlTIy7IXA1mhjOSAqWRUAuil+VL3T/NC4
MtIXkhALiyn8LOn1tUQCZGrQNzDksZmgVYlStJzGskJ03ukZtDLHqYCzpv/nuk6e
Px7G8VcN2oHfniukPBF/IRwSxWP4rtz1YIezC9u8vCS1fTgF0PLY424l44M0dSUs
oLC3G9/raTNAmPUB0Lxpy6Y00Yt5Y4TtHRKYLcALQr4HKiwSHAdqZrfLoprPn0EJ
XzEAdrvtv0R/1fHDJJ4xXQnOB5Hu7umD1T2LzHPpTzgKWJWREdV76yUGg/KbIjmj
DelMKXVNy5CnH+JzQR2uIerluosshnKVdQgi3YgFM6tQLkScbPO74tWxHMj3wvFC
zu6AcNQOFm593Bm/jvQCSdL9XeaIkjaFLhcRGdkrAK68mP4ohUTEHNDLg1LtV0+x
QifESGeEyern2keMiKtO67AlTmTnaCHzWZtcahUZpOaH7KqD/ESYXfdC17i2Du8A
EQEAAYkCNgQYAQgAIBYhBBDXAEmP6tpK1laIpfH/sG9pZAPnBQJm4TZfAhsMAAoJ
EPH/sG9pZAPnJvIP/iqm19gvQhFepqt5urXrbQDpk+Po26fTkdvBj/vImrWsgSLP
BfYHHwz+DniWx6LENg+SfrJccj2EcL07vVXnyRg9kMMMDhhnFWI9VZ+dosvIEEyR
0tTRkMWMl7wNZ6iQ1Cg/Y9MUmIdjAS2NC/fwQEeYIm+rWMFLu5Zc0A6n3F+0EYR4
SEx1vTT15Ymm2EoNWpKhxE6tUuYd+SKCQCDn2PmKF6T1ddVOS7ZWaWjd7KX26nK3
J9ZPMiFLfXdY89xM8UMvqyEQPovqJsMETQs1RlluHlla9w3kDUKqJvEIfOX4FNUC
kMxBzScff506O+5E82yHpN41K2QE4rFmHD1zw9egojvZayhxF1E9euhtjgfEsL3O
imIJ1PsoVYPggFes/QcqBqegJE86ZKKlXl+zdLXcZzhn4G1wwCAgoET4ZoZD8WLi
HiMxfdngnUlDq7kXrwCf/M2lTqEyJSuUBl168e0oZL18LWDlGMce+TpTYYxfKFNa
5FjGy8+fPLoVP87Lu/w2ALb3SrFF2mqOrbKEkhoRTzgYLYPYqS07m+JpYv5MO0NA
MrOCU9Ub9poue2kg/qtHdgGOHXoO8rb+pXWw/I7oHPF0+cBHr68rd5FatyABzC2s
stTmQbpRNkWeY1dmK4UOrIl2jXZZDvxfKCs5S28MbOqzntvKuVTbPZAv/XJ5
=4LYY
-----END PGP PUBLIC KEY BLOCK-----
`;
  const iMessageCode = "APKTIDhQLXF1nLw6Jo9Ua-f-vvjK6AIaizX_pslziSNiFUdKMvBg";

  const LockButton = ({ onClick }) => (
    <button
      onClick={onClick}
      style={{
        marginLeft: "10px",
        background: "#4CAF50",
        border: "none",
        color: "white",
        padding: "5px 10px",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontSize: "16px",
        borderRadius: "5px",
        cursor: "pointer",
        transition: "background-color 0.3s",
      }}
      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#45a049")}
      onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#4CAF50")}
    >
      🔒
    </button>
  );

  return (
    <div className="App">
      <div className="content-wrapper">
        <div className="photo-container">
          <div className="photo-circle"></div>
        </div>
        <div className="info-container">
          <div className="title-container">
            <div className="name-animation-container">
              <h1 className="title" ref={nameRef}>
                {names[nameIndex].text}
              </h1>
              {names[nameIndex].subtext && (
                <p className="subtext" ref={subtextRef}>
                  {names[nameIndex].subtext}
                </p>
              )}
            </div>
          </div>
          <h2 className="job-title">Software Engineer and Teacher in Kyoto</h2>
          <div className="profile">
            <div
              className="profile-item"
              style={{
                backgroundColor: "#f0f0f0",
                padding: "10px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
              }}
            >
              <strong style={{ color: "#1e3c72", fontSize: "1.1em" }}>
                Email/iMessage:
              </strong>
              <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "mailto:ryo.shin.83@icloud.com";
                  }}
                  style={{
                    color: "#2a5298",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  ryo.shin.83@icloud.com
                </a>
                <LockButton onClick={() => setIsContactModalOpen(true)} />
              </div>
            </div>
            <div className="profile-item">
              <strong>Social Media:</strong>
              <div className="social-media-links">
                <a
                  href="https://www.facebook.com/ryoshin0830"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-media-link facebook"
                >
                  Facebook: ryoshin0830
                </a>
                <a
                  href="https://www.instagram.com/ryoshin0830?igsh=aXNsYWZ2bHpqdGZ0"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-media-link instagram"
                >
                  Instagram: ryoshin0830
                </a>
                <a
                  href="https://work.weixin.qq.com/ca/cawcdede618ff0124f"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-media-link wechat"
                >
                  WeChat: liangzhen0830
                </a>
                <a
                  href="https://line.me/ti/p/J7cd9CqhvX"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-media-link line"
                >
                  Line: fyuneru0830
                </a>
                <a
                  href="https://t.me/ifdotgy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-media-link telegram"
                >
                  Telegram: ifdotgy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactModal
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
        onSelectEmail={() => {
          setIsContactModalOpen(false);
          setIsPGPModalOpen(true);
        }}
        onSelectIMessage={() => {
          setIsContactModalOpen(false);
          setIsIMessageModalOpen(true);
        }}
      />
      <PGPModal
        isOpen={isPGPModalOpen}
        onClose={() => setIsPGPModalOpen(false)}
        publicKey={pgpPublicKey}
        fingerprint={pgpFingerprint}
      />
      <IMessageModal
        isOpen={isIMessageModalOpen}
        onClose={() => setIsIMessageModalOpen(false)}
        iMessageCode={iMessageCode}
      />
    </div>
  );
}

export default App;
